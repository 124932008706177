/* Navbar */
.navbar {
  display: flex;
  flex-direction: column; 
  height: 30vh; 
  background-color: rgb(4, 22, 82);
  color: white;
}

.nav-container {
  display: flex;
  flex-direction: column;
}

.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.nav-item {
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #555; 
  padding: 8px;
  border-radius: 5px; 
}

.nav-item:hover{
  background-color: rgb(22, 44, 114);
}

.nav-link {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-family: 'Noto Sans', sans-serif;
}

.navbar-image {
  max-width: 90%;
  max-height: 90%;
  height: auto;
  object-fit: cover;
  outline: 2px solid rgb(81, 80, 92);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

h1 {
  padding-top: 5%;
  font-size: 2.5rem; /* Adjust the font size */
  color: #333; /* Set the text color */
  text-align: center; /* Center-align the text */
  letter-spacing: 2px; /* Add some letter spacing */
  margin-bottom: 20px; /* Add some bottom margin */
}

/* Door Finder */
.door-finder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 200vh;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Door Finder Form */
.door-finder-form{
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  margin: 0; 
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

.raynor-logo{
  padding-top: 7%;
}

.about-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-text {
  font-family: 'Arial', sans-serif;
  font-size: 1.5em;
  color: #333;
  line-height: 1.5;
}

.error_code {
  color: #ff0000;
  font-size: 14px;
  margin-top: 5px;
}

/* Styles for the grid container */
.door-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  max-width: 800px;
  margin: 20px auto;
}

/* Styles for each door information container */
.door-info-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Styles for the door information */
.door-info-container p {
  font-size: 16px;
  color: #333;
}

/* Styles for header container */
.job-selected-container {
  max-width: 800px;
  margin: 70px auto 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Styles for the address */
.job-selected-container h1 {
  font-size: 24px;
  color: #333;
}

/* Styles for the date of install */
.job-selected-container p {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

/* Styles for the notes section */
.job-selected-container .job-notes {
  margin-top: 15px;
}

/* Styles for individual notes */
.job-selected-container .job-notes div {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #ebeaea;
}

.job-selected-container .job-notes p {
  font-size: 14px;
  color: #2e2d2d;
  margin: 0;
}

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login_card {
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20%;
  background-color: #fff;
}


.login_card h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.login_card input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.login_card button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login_card button:hover {
  background-color: #45a049;
}

.login_card form {
  display: flex;
  flex-direction: column;
}

.job-notes .delete_button,
.job-notes .edit_button {
  float: right;
  background-color: transparent;
  color: inherit;
  padding: 0px; 
  margin: 3px;
  border: none; 
  border-radius: 5; 
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 3%;
}

.job-notes .delete_button:hover,
.job-notes .edit_button:hover {
  background-color: gray;
}

.door_notes .delete_button,
.door_notes .edit_button {
  float: right;
  background-color: transparent;
  color: inherit;
  padding: 0px; 
  margin: 3px;
  border: none; 
  border-radius: 5; 
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 6%;
}

.door_notes .delete_button:hover,
.door_notes .edit_button:hover {
  background-color: gray;
}

.door_notes{
  font-size: 14px;
  color: #2e2d2d;
  margin: 0;
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #ebeaea;
}

.employee_form_card {
  text-align: center;
  max-width: 400px;
  margin: 70px auto;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.employee-form h1 {
  color: #333;
  margin-bottom: 20px;
}

.employee-form {
  display: flex;
  flex-direction: column;
}

.employee-form input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* AddDoor.css */

.add-door-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.main-form {
  display: flex;
  flex-direction: column;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.select-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.nested-form {
  display: flex;
  flex-direction: column;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #45a049;
}

.admin_job_card {
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.job-card {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.door-card {
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.confirmation_text {
  background-color: rgba(46, 204, 113, 0.6); /* Green background color with 60% opacity */
  color: white; /* White text color */
  padding: 10px; /* Padding around the text */
  margin: 10px 0; /* Margin to create some space around the text */
  border-radius: 5px; /* Rounded corners for a nicer look */
}

.admin-bio-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin-name {
  font-size: 24px;
  margin-bottom: 10px;
}

.admin-position, .admin-email {
  font-size: 16px;
  margin-bottom: 8px;
}

.admin-avatar {
  width: 100%;
  max-width: 200px;
  border-radius: 50%;
  margin-top: 10px;
}